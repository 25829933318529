<script>
  import Alert from "./Alert.svelte";
  import { isValidURL, isValidSlug, normalizeURL } from "./utils";
  import Clipboards from "./Clipboards.svelte";

  let url = "";
  let slug = "";
  let loading = false;
  let errorMessage = "";
  let shortUrls = [];

  function validate() {
    url = normalizeURL(url);
    if (!url || !isValidURL(url)) {
      errorMessage = "Invalid URL!";
      return false;
    }
    if (url.includes("idfk.lol")) {
      errorMessage = "Naughty naughty, you teasing me naughty naughty";
      return false;
    }
    if (!isValidSlug(slug)) {
      errorMessage = "Slug has invalid characters.";
      return false;
    }
    return true;
  }

  $: if (errorMessage) {
    setTimeout(() => {
      errorMessage = false;
    }, 2000);
  }

  async function getShortURL() {
    if (!validate()) return;
    loading = true;
    try {
      const response = await fetch("https://idfk.lol/url", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          url,
          slug
        })
      });
      let data = await response.json();
      if (data.success) {
        shortUrls = [data.message, ...shortUrls];
        url = "";
      } else {
        if (typeof data.message === "string") errorMessage = data.message;
        if (Array.isArray(data.message)) errorMessage = data.message[0].msg;
      }
    } catch (e) {
      errorMessage = "Something went wrong, buddy!";
    }
    loading = false;
  }
</script>

<style>
  section {
    background-color: rgb(11, 23, 54);
    padding: 20px 0;
    margin: 3rem 0;
  }

  .shorten-link-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  input {
    flex: 2;
    height: 60px;
    display: inline-block;
    padding: 0px 20px;
    margin: 0;
    margin-right: 50px;
    font-size: 20px;
    font-weight: 300;
    color: rgb(54, 56, 59);
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 212, 215);
    border-radius: 8px;
  }

  input::placeholder {
    color: #c7bfbf;
  }

  button {
    flex-grow: 1;
    max-width: 25%;
    order: 3;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.11;
    color: rgb(255, 255, 255);
    background: #2a5bd7;
    padding: 18px 30px;
    border-radius: 8px;
    outline: none;
    border: none;
  }

  .alert-warpper {
    width: 100%;
    order: 4;
  }

  button:disabled,
  input:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  button:not(:disabled):active,
  button:not(:disabled):hover,
  button:not(:disabled):hover {
    background-color: rgb(27, 57, 135);
    filter: brightness(140%);
  }

  button:not(:disabled):focus {
    box-shadow: 0 0 0 2px rgba(206, 218, 250, 1);
  }

  @media (max-width: 991px) {
    section {
      padding: 40px 0;
      margin: 1rem 0;
    }

    .shorten-link-action {
      flex-direction: column;
    }

    .alert-warpper {
      order: 3;
    }

    button {
      order: 4;
      max-width: 100%;
    }

    button,
    input {
      width: 100%;
      height: 50px;
      padding: 15px;
      font-size: 16px;
      margin: 0;
      margin-top: 1rem;
    }
  }
</style>

<section>
  <div class="container shorten-link">
    <div class="shorten-link-action">
      <input
        placeholder="http://some-long.link/that-you-do-not-care-about"
        type="text"
        autocorrect="off"
        autocapitalize="none"
        bind:value={url}
        disabled={loading}
        on:keydown={e => e.key === 'Enter' && getShortURL()} />
      <!-- <input
              placeholder="Slug.."
              type="text"
              bind:value={slug}
              disabled={loading} /> -->

      {#if !!errorMessage}
        <div class="alert-warpper">
          <Alert message={errorMessage} />
        </div>
      {/if}
      <button on:click={getShortURL} disabled={loading}>
        {loading ? 'Shortening..' : 'Shorten'}
      </button>
    </div>

    <Clipboards shortUrls={shortUrls.slice(0, 2)} />

  </div>
</section>

<script>
  import Clipboard from "./Clipboard.svelte";
  import { fade } from "svelte/transition";

  export let shortUrls;
  let urls;
  $: urls = shortUrls;
</script>

<div>
  {#each shortUrls as shortUrl, i}
    <div transition:fade>
      <Clipboard {shortUrl} />
    </div>
  {/each}
</div>

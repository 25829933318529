<script>
  import { onMount } from "svelte";
  onMount(async () => {
    !(function(e, i, n, s) {
      var t = "InfogramEmbeds",
        d = e.getElementsByTagName("script")[0];
      if (window[t] && window[t].initialized)
        window[t].process && window[t].process();
      else if (!e.getElementById(n)) {
        var o = e.createElement("script");
        (o.async = 1),
          (o.id = n),
          (o.src = "https://e.infogram.com/js/dist/embed-loader-min.js"),
          d.parentNode.insertBefore(o, d);
      }
    })(document, 0, "infogram-async");
  });
</script>

<style>
  section {
    line-height: 1.4;
  }
  a {
    color: #2a5bd7;
    text-align: center;
    display: block;
    margin-bottom: 1rem;
  }

  article {
    padding: 0 1rem;
  }

  .infogram-embed {
    margin-top: 2rem;
  }

  :global(iframe) {
    border-radius: 0.5rem;
  }

  @media (max-width: 991px) {
    article {
      padding: 0;
    }
  }
</style>

<section>
  <div
    class="infogram-embed"
    data-id="4400843a-a2cf-4a99-8aa3-085418601f0d"
    data-type="interactive"
    data-title="Yemen crisis snapshot" />

  <article>
    <p>
      <strong>
        Yemen is now the world's largest humanitarian crisis and children are
        being deprived of their futures.
      </strong>
    </p>

    <p>
      More than 24 million people (80% of the country's population), including
      more than 12 million children, need humanitarian assistance. With COVID-19
      spreading exponentially, Yemen faces an emergency within a crisis. Hygiene
      and clean water runs short, while just half of health facilities operate
      and many of them lacking basic essentials such as masks and gloves, let
      alone oxygen and other vital supplies for treating coronavirus.
    </p>
  </article>

  <a class="donate-now" target="_blank" href="https://idfk.lol/donate">
    Donate Now
  </a>
</section>

<script>
  import CopyToClipboard from "svelte-copy-to-clipboard";
  export let shortUrl;
  let copied = false;
  let url = shortUrl.url;
  let slug = `https://idfk.lol/${shortUrl.slug}`;

  $: {
    url = shortUrl.url;
    slug = `https://idfk.lol/${shortUrl.slug}`;
  }

  $: if (copied) {
    setTimeout(() => {
      copied = false;
    }, 2000);
  }
</script>

<style>
  .action {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-radius: 8px 8px 8px 8px;
    margin-top: 14px;
    background-color: #fff;
  }
  .copy-button {
    width: 95px;
    background: #edf2fe;
    color: #2a5bd7;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 20px;
  }

  .copied {
    background: #649949;
    color: #fff;
  }

  .right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
  .shorten-link {
    color: #2a5bd7;
    padding-right: 25px;
    text-decoration: none;
  }

  .copy-button:not(.copied):active,
  .copy-button:not(.copied):hover,
  .copy-button:not(.copied):hover {
    background: #edf2fe;
    filter: brightness(95%);
  }

  .copy-button:not(.copied):focus {
    box-shadow: 0 0 0 2px rgba(206, 218, 250, 1);
  }

  .orginal-url {
    width: 40%;
  }

  .shorten-link,
  .orginal-url {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    .action {
      flex-direction: column;
      align-items: flex-start;
    }

    .copy-button,
    .shorten-link,
    .orginal-url,
    .right-section {
      width: 100%;
      display: block;
    }

    .copy-button,
    .shorten-link,
    .orginal-url {
      padding: 6px 0;
    }
  }
</style>

<main>
  <CopyToClipboard on:copy={() => (copied = true)} text={slug} let:copy>
    <div class="action">
      <div class="orginal-url">{url}</div>
      <div class="right-section">
        <a href={slug} class="shorten-link" target="_blank">{slug}</a>
        <button class="copy-button" class:copied on:click={copy}>
          {copied ? 'Copied!' : 'Copy'}
        </button>
      </div>
    </div>
  </CopyToClipboard>
</main>

<script>
  import { getContext } from "svelte";
  import Donation from "./Donation.svelte";
  const { open } = getContext("simple-modal");

  const showDonation = () => {
    open(Donation);
  };
</script>

<style>
  button {
    padding: 8px 16px;
    background: #2a5bd7;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
  }

  button:not(:disabled):active,
  button:not(:disabled):hover,
  button:not(:disabled):hover {
    background-color: rgb(27, 57, 135);
    filter: brightness(140%);
  }

  button:not(:disabled):focus {
    box-shadow: 0 0 0 2px rgba(206, 218, 250, 1);
  }

  @media (max-width: 991px) {
    button {
      padding: 7px 14px;
    }
  }
</style>

<button on:click={showDonation}>Donate</button>

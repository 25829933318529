import isURL from 'validator/lib/isURL'

export const isValidURL = (str) => {
  return isURL(str)
}

export const isValidSlug = (str) => {
  const pattern = new RegExp(/^$|[a-z0-9_\-]/i)
  return !!pattern.test(str)
}

export const normalizeURL = (url) => {
  if (!url) return
  let normalizedURL = url
  if (!normalizedURL.includes('https' && 'ftp' && 'http')) {
    return `http://${normalizedURL}`
  } else {
    return normalizedURL
  }
}

export const normalizeSlug = (slug) => {
  if (!slug) return
  return slug.split('lol/')[1]
}

<script>
  import { fade } from "svelte/transition";
  import Alert from "./Alert.svelte";
  import { onMount } from "svelte";
  import {
    normalizeSlug,
    isValidSlug,
    isValidURL,
    normalizeURL
  } from "./utils";

  let url = "";
  let slug = "";
  let loading = false;
  let errorMessage = "";
  let APIres = "";
  let imageSeries = "";
  let showMap = false;

  $: if (errorMessage) {
    setTimeout(() => {
      errorMessage = false;
    }, 2000);
  }

  function validate() {
    APIres = "";
    slug = normalizeSlug(url);
    url = normalizeURL(url);
    if (!url || !isValidURL(url) || !url.includes("idfk.lol")) {
      errorMessage = "You must use a valid idfk.lol URL";
      return false;
    }
    if (!slug) {
      errorMessage = "Naughty naughty, you teasing me naughty naughty";
      return false;
    }
    return true;
  }

  async function getClicks() {
    if (!validate()) return;
    loading = true;
    try {
      const response = await fetch(`https://idfk.lol/url/${slug}`, {
        method: "POST"
      });
      let data = await response.json();
      if (data.success) {
        APIres = data.message;
        showMap = true;
      } else {
        if (typeof data.message === "string") errorMessage = data.message;
        if (Array.isArray(data.message)) errorMessage = data.message[0].msg;
      }
    } catch (e) {
      errorMessage = "Something went wrong, buddy!";
    }
    loading = false;
  }

  onMount(() => {
    am4core.ready(async function() {
      am4core.useTheme(am4themes_animated);
      var chart = am4core.create("chartdiv", am4maps.MapChart);
      chart.geodata = am4geodata_worldLow;
      chart.projection = new am4maps.projections.Miller();
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.exclude = ["AQ"];
      polygonSeries.useGeodata = true;
      var polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.polygon.fillOpacity = 0.6;
      var hs = polygonTemplate.states.create("hover");
      hs.properties.fill = chart.colors.getIndex(0);
      imageSeries = chart.series.push(new am4maps.MapImageSeries());
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";

      chart.seriesContainer.draggable = false;
      chart.seriesContainer.resizable = false;
      chart.maxZoomLevel = 1;

      var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 0.3;
      circle.propertyFields.fill = "color";

      var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 0.3;
      circle2.propertyFields.fill = "color";

      circle2.events.on("inited", function(event) {
        animateBullet(event.target);
      });

      function animateBullet(circle) {
        var animation = circle.animate(
          [
            { property: "scale", from: 1, to: 3 },
            { property: "opacity", from: 1, to: 0 }
          ],
          1000,
          am4core.ease.circleOut
        );
        animation.events.on("animationended", function(event) {
          animateBullet(event.target.object);
        });
      }
    });
  });

  $: {
    if (APIres) {
      var colorSet = new am4core.ColorSet();
      imageSeries.data = APIres.locations.map(location => ({
        title: location.country,
        latitude: Number(location.latitude),
        longitude: Number(location.longitude),
        color: colorSet.next()
      }));
    }
  }
</script>

<style>
  section {
    margin: 3rem 0;
  }

  .search-wrapper {
    padding: 20px 0;
  }

  .search-wrapper > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  input {
    flex: 2;
    height: 60px;
    display: inline-block;
    padding: 0px 20px;
    margin: 0;
    margin-right: 50px;
    font-size: 20px;
    font-weight: 300;
    color: rgb(54, 56, 59);
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 212, 215);
    border-radius: 8px;
  }

  input::placeholder {
    color: #c7bfbf;
  }

  button {
    flex-grow: 1;
    max-width: 25%;
    order: 3;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.11;
    color: rgb(255, 255, 255);
    background: #2a5bd7;
    padding: 18px 30px;
    border-radius: 8px;
    outline: none;
    border: none;
  }

  .alert-warpper {
    width: 100%;
    order: 4;
  }

  button:disabled,
  input:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  button:not(:disabled):active,
  button:not(:disabled):hover,
  button:not(:disabled):hover {
    background-color: rgb(27, 57, 135);
    filter: brightness(140%);
  }

  button:not(:disabled):focus {
    box-shadow: 0 0 0 2px rgba(206, 218, 250, 1);
  }

  .map-wrapper {
    position: relative;
    display: none;
  }

  .showMap {
    display: inherit;
  }

  #chartdiv {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    overflow: hidden;
  }

  .total {
    margin-bottom: 1rem;
    text-align: left;
  }
  .total-text {
    margin-right: 0.3rem;
    display: inline-block;
    color: #a0aec0;
  }

  .total-count {
    font-size: 18px;
    width: 18px;
    height: 22px;
  }
  @media (max-width: 991px) {
    section {
      margin: 0;
    }
    .search-wrapper > div {
      flex-direction: column;
      margin-bottom: 0;
    }

    .alert-warpper {
      order: 3;
    }

    button {
      order: 4;
      max-width: 100%;
    }

    button,
    input {
      width: 100%;
      height: 50px;
      padding: 15px;
      font-size: 16px;
      margin: 0;
      margin-top: 1rem;
    }

    #chartdiv {
      width: 100%;
      height: 300px;
    }
    input {
      margin: 0;
    }
    .total {
      margin-bottom: 0;
    }
  }
</style>

<section>
  <div class="search-wrapper">
    <div class="container">
      <input
        id="analytics-input"
        placeholder="https://idfk.lol/something"
        bind:value={url}
        type="text"
        autocorrect="off"
        autocapitalize="none" />
      {#if !!errorMessage}
        <div class="alert-warpper">
          <Alert message={errorMessage} />
        </div>
      {/if}
      <button on:click={getClicks} disabled={loading}>
        {loading ? 'Finding..' : 'Find'}
      </button>
    </div>
  </div>

  <div class="map-wrapper container" class:showMap transition:fade>
    <div class="total">
      <span class="total-text">Number of Clicks:</span>
      <strong class="total-count">{APIres ? APIres.count : '🤔'}</strong>
    </div>
    <div id="chartdiv" />
  </div>

</section>

<script>
    import {onMount} from "svelte";
    import Nav from "./components/Nav.svelte";
    import Banner from "./components/Banner.svelte";
    import Shorten from "./components/Shorten.svelte";
    import Analytics from "./components/Analytics.svelte";
    import Footer from "./components/Footer.svelte";

    onMount(() => {
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: "#edeff5",
                    text: "#838391"
                },
                button: {
                    background: "#2a5bd7"
                }
            },
            theme: "classic",
            position: "bottom-right",
            content: {
                href: 'https://idfk.lol/privacy-policy',
                message:
                        "We encourage you to review our policies before using the website. By continuing to use the service, or by closing this dialog, you agree to our policies."
            }
        });
    });
</script>

<style>
    main {
        height: 100%;
        width: 100%;
        font-family: "Montserrat", sans-serif;
    }

    :global(html) {
        scroll-behavior: smooth;
    }

    :global(.container) {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    :global(.cc-message) {
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
    }

    @media (min-width: 768px) {
        :global(.container) {
            width: 750px;
        }
    }

    @media (min-width: 992px) {
        :global(.container) {
            width: 970px;
        }
    }

    @media (min-width: 1200px) {
        :global(.container) {
            width: 1170px;
        }
    }
</style>

<main>
    <Nav/>
    <Banner/>
    <Shorten/>
    <Analytics/>
    <Footer/>
</main>

<script>
  import FavIcon from "../../public/images/favicon.svg";
</script>

<style>
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    font-size: 14px;
    border-top: 1px solid #e8e8e8;
    line-height: 1.5;
  }

  button {
    background-color: #fff;
    cursor: pointer;
    color: #000;
  }

  .fav-icon {
    width: 30px;
    height: 30px;
  }

  .name {
    letter-spacing: 0.5px;
    font-weight: 600;
  }

  .details {
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  .terms > a:first-child {
    margin-right: 0.5rem;
  }

  .terms > a {
    color: black;
  }

  a {
    font-size: 12px;
  }
  @media (max-width: 991px) {
    footer {
      flex-direction: column-reverse;
    }

    .details {
      margin-top: 6px;
    }

    .site-meta-data {
      display: flex;
    }

    .site-meta-data > div {
      margin-right: 5px;
    }
  }
</style>

<svelte:head>
  <script src="https://feedback.fish/ff.js?pid=a89f7b517a190d">

  </script>
</svelte:head>

<div class="container">

  <footer>
    <div class="site-meta-data">
      <div class="name">idfk.lol</div>
      <div>© {new Date().getFullYear()}</div>
    </div>

    <div class="details">
      <div class="terms">
        <a href="https://idfk.lol/terms-of-service" target="_blank">Terms of Service</a>
        <a href="https://idfk.lol/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
      <button data-feedback-fish>Send Feedback</button>
    </div>

    <div class="fav-icon">
      <FavIcon />
    </div>

  </footer>
</div>

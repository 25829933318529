<script>
  import { fade } from "svelte/transition";
  export let message;
</script>

<style>
  .alert-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 16px;
    font-size: 18px;
    font-weight: 300;
    color: #731b14;
    background: #fa9b93;
    border-radius: 8px;
    padding: 0 20px;
  }
</style>

<div class="alert-wrapper" transition:fade>{message}</div>
